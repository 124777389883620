<script>
import axios from 'axios';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default {
    props: {
        args: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            icons: {
                faSpinner
            },
            runningAction: false,
            bulkActionArguments: {
                assignable_id: null,
                task_status_id: null,
            }
        }
    },

    computed: {
        taskFeature() {
            return window.MAT.features.find(f => f.name === 'tasks');
        },
        taskStatusesModel() {
            return this.taskFeature.options.task_statuses;
        }
    },

    methods: {
        async runBulkAction() {

            try {
                this.runningAction = true;
                const response = await axios.patch(
                    '/api/tasks/bulk_update',{
                        action: this.args.action.type,
                        selectedTasks: this.args.selectedRecords.map(task => {
                            return task.id;
                        }),
                        args: this.bulkActionArguments,
                    },
                    {headers: {
                        'Accept': 'application/vnd.api+json',
                    }}
                )
                this.$emit('bulkActionCompleted', response.data.meta.success);

            }catch(err) {
                window.notify.apiError(err);
            }finally {
                this.runningAction = false;
            }
        },
        removeTaskAssignees() {
            this.bulkActionArguments.assignable_id = "unassigned";
            this.runBulkAction();
        },
        closeActionComponent() {
            this.$emit("closeActionComponent");
        }
    }
}
</script>
<template>
    <modal modal-width="60%" @close="closeActionComponent">
        <template #header>{{ args.action.title }}</template>
        <template #body>
            <div class="min-h-35vh mt-3">
                <div class="flex justify-center">
                    <form-autocomplete
                        v-if="args.action.type === 'update_assignee'"
                        field-name="update-project-status-action-handler-select"
                        options-url="/api/crm/users"
                        option-label-field="user_select_title"
                        option-value-field="id"
                        v-model="bulkActionArguments.assignable_id"
                        label="New Assignee"
                        class="w-2/3"
                        :use-json-api="true"
                    ></form-autocomplete>
                    <form-select
                        v-if="args.action.type === 'update_task_status'"
                        field-name="update-project-status-action-handler-select"
                        label="New Task Status"
                        :options-url="'/api/lookups?model=' + taskStatusesModel"
                        option-label-field="name"
                        option-value-field="id"
                        class="w-2/3"
                        v-model="bulkActionArguments.task_status_id"
                    />

                </div>
                <div class="flex justify-around">

                    <button
                        class="inputbutton1"
                        @click="runBulkAction"
                        :disabled="runningAction"
                    >
                        <font-awesome-icon
                            v-if="runningAction"
                            :icon="icons.faSpinner"
                            :spin="true"
                        />
                        Update Selected Tasks
                    </button>
                    <button
                        class="inputbutton1"
                        @click="removeTaskAssignees"
                        :disabled="runningAction"
                        v-if="args.action.type === 'update_assignee'"
                    >
                        <font-awesome-icon
                            v-if="runningAction"
                            :icon="icons.faSpinner"
                            :spin="true"
                        />
                        Remove Assignee
                    </button>
                </div>
            </div>
        </template>
    </modal>
</template>
